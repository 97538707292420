exports.components = {
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pl-js": () => import("./../../../src/pages/index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */),
  "component---src-pages-media-index-js": () => import("./../../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-media-preview-js": () => import("./../../../src/pages/media/preview.js" /* webpackChunkName: "component---src-pages-media-preview-js" */),
  "component---src-pages-media-strapi-brookfield-medias-slug-js": () => import("./../../../src/pages/media/{StrapiBrookfieldMedias.slug}.js" /* webpackChunkName: "component---src-pages-media-strapi-brookfield-medias-slug-js" */),
  "component---src-pages-pliki-cookie-en-js": () => import("./../../../src/pages/pliki-cookie.en.js" /* webpackChunkName: "component---src-pages-pliki-cookie-en-js" */),
  "component---src-pages-pliki-cookie-js": () => import("./../../../src/pages/pliki-cookie.js" /* webpackChunkName: "component---src-pages-pliki-cookie-js" */),
  "component---src-pages-pliki-cookie-pl-js": () => import("./../../../src/pages/pliki-cookie.pl.js" /* webpackChunkName: "component---src-pages-pliki-cookie-pl-js" */),
  "component---src-pages-polityka-prywatnosci-en-js": () => import("./../../../src/pages/polityka-prywatnosci.en.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-en-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-polityka-prywatnosci-pl-js": () => import("./../../../src/pages/polityka-prywatnosci.pl.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-pl-js" */)
}

